import React, { useEffect, useRef } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { srConfig } from '@config';
import sr from '@utils/sr';
import { usePrefersReducedMotion } from '@hooks';

const StyledAboutSection = styled.section`
  max-width: 900px;

  .inner {
    display: grid;
    grid-template-columns: 3fr 2fr;
    grid-gap: 50px;

    @media (max-width: 768px) {
      display: block;
    }

    p{
      font-size: 1.2rem;
      text-align: justify;
    }
  }
`;
const StyledText = styled.div`
  ul.skills-list {
    display: grid;
    grid-template-columns: repeat(2, minmax(140px, 220px));
    grid-gap: 0 10px;
    padding: 0;
    margin: 20px 0 0 0;
    overflow: hidden;
    list-style: none;

    li {
      position: relative;
      margin-bottom: 10px;
      padding-left: 20px;
      font-family: var(--font-mono);
      font-size: var(--fz-xs);

      &:before {
        content: '▹';
        position: absolute;
        left: 0;
        color: var(--green);
        font-size: var(--fz-sm);
        line-height: 12px;
      }
    }
  }
`;
const StyledPic = styled.div`
  position: relative;
  max-width: 300px;

  @media (max-width: 768px) {
    margin: 50px auto 0;
    width: 70%;
  }

  .wrapper {
    ${({ theme }) => theme.mixins.boxShadow};
    display: block;
    position: relative;
    //width: 100%;
    width: 200px;
    height: 200px;
    margin-bottom: 40px;

    border-radius: var(--border-radius);
    background-color: var(--green);

    &:hover,
    &:focus {
      background: transparent;
      outline: 0;

      &:after {
        top: 15px;
        left: 15px;
      }

      .img {
        filter: none;
        mix-blend-mode: normal;
      }
    }

    .img {
      width: 200px;
      height: 200px;
  
      position: relative;
      border-radius: var(--border-radius);
      mix-blend-mode: multiply;
      filter: grayscale(100%) contrast(1);
      transition: var(--transition);
    }

    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      //width: 100%;
      //height: 100%;
      width: 200px;
      height: 200px;
  
      border-radius: var(--border-radius);
      transition: var(--transition);
    }

    &:before {
      top: 0;
      left: 0;
      background-color: var(--navy);
      mix-blend-mode: screen;
    }

    &:after {
      border: 2px solid var(--green);
      top: 20px;
      left: 20px;
      z-index: -1;
    }
  }
`;

const About = () => {
  const revealContainer = useRef(null);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    sr.reveal(revealContainer.current, srConfig());
  }, []);

  const skills = ['Soluciones empresariales', 'Arquitectura', 'Dirección de proyectos', 'Outsourcing TI', 'Marketing Digital', 'Capacitación'];

  return (
    <StyledAboutSection id="about" ref={revealContainer}>
      <h2 className="numbered-heading">Nosotros</h2>

      <div className="inner">
        <StyledText>
          <div>
            <h3 className="unnumbered-subheading">¿Quiénes somos?</h3>
            <p>
              Somos un equipo de profesionales con experiencia y excelencia tecnológica (ágil) y de consultoría que 
              decidieron emprender hace 10 años en desarrollo de software y tecnología, con mucho compromiso y pasión, 
              adictos al mejor diseño, metodología e innovación, con la convicción de generar valor a diversos procesos 
              de desarrollo de tu empresa para alcanzar su máximo potencial.        
            </p>

            <h3 className="unnumbered-subheading">¿Como lo hacemos?</h3>
            <p>
              El principal ingrediente es la <span className="destacaded">Metodología Ágil</span>, alta calidad asegurada en cada proyecto.
              Trabajo colaborativo con el cliente y su red interna
              Rápida flexibilidad y respuesta ante un control de cambios, 5% cantidad de horas, libre elección.
              Énfasis en workshop y puesta en marcha de cada proyecto, tiempo de dedicación en el requerimiento del cliente.
              Alta planificación y compromiso fechas de entrega con seguimientos semanales para mitigar riesgos y problemas.              
            </p>
            <p>
              <span className="destacaded">Equipo Profesional</span>, multidisciplinario con experiencia previa en empresas lideres del mercado.
              Especialización permanente en capacitación con tecnología, últimas tendencias y herramientas esenciales en cada solución con una visión integral, 
              valores Internos, respeto, honestidad, conciencia social, liderazgo y compromiso que nos identifican.
              Apoyo permanente a fundaciones sin fines lucro.
            </p>
            <p>Un resumen de las cosas que hacemos y nuestra motivación:</p>
          </div>

          <ul className="skills-list">
            {skills && skills.map((skill, i) => <li key={i}>{skill}</li>)}
          </ul>
        </StyledText>

        <StyledPic>
          {/* <div className="wrapper">
            <StaticImage
              className="img"
              src="../../images/nosotros.png"
              width={450}
              quality={95}
              formats={['AUTO', 'WEBP', 'AVIF']}
              alt="Headshot"
            />
          </div>

          <div className="wrapper">
            <StaticImage
              className="img"
              src="../../images/scrum.png"
              width={450}
              quality={95}
              formats={['AUTO', 'WEBP', 'AVIF']}
              alt="Headshot"
            />
          </div>

          <div className="wrapper">
            <StaticImage
              className="img"
              src="../../images/equipo.png"
              width={450}
              quality={95}
              formats={['AUTO', 'WEBP', 'AVIF']}
              alt="Headshot"
            />
          </div> */}

          <div className="wrapper">
            <StaticImage
              className="img"
              src="../../images/gaguilera.jpeg"
              width={350}
              quality={95}
              formats={['AUTO', 'WEBP', 'AVIF']}
              alt="Headshot"
            />
          </div>
          <div className="wrapper">
            <StaticImage
              className="img"
              src="../../images/csaldias.jpeg"
              width={350}
              quality={95}
              formats={['AUTO', 'WEBP', 'AVIF']}
              alt="Headshot"
            />
          </div>
          <div className="wrapper">
            <StaticImage
              className="img"
              src="../../images/fschatzke.jpg"
              width={250}
              quality={95}
              formats={['AUTO', 'WEBP', 'AVIF']}
              alt="Headshot"
            />
          </div>

        </StyledPic>
      </div>
    </StyledAboutSection>
  );
};

export default About;
