import React, { useEffect, useRef } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { srConfig } from '@config';
import sr from '@utils/sr';
import { usePrefersReducedMotion } from '@hooks';

const StyledServicesSection = styled.section`
  max-width: 900px;

  .inner {
    display: grid;
    grid-template-columns: 10fr 2fr;
    grid-gap: 50px;

    @media (max-width: 768px) {
      display: block;
    }

    p{
      font-size: 1.2rem;
      text-align: justify;
    }
  }
`;
const StyledText = styled.div`
  h3{
    padding-top: 10px;
  }

  ul.skills-list {
    display: grid;
    grid-template-columns: repeat(2, minmax(140px, 220px));
    grid-gap: 0 10px;
    padding: 0;
    margin: 20px 0 0 0;
    overflow: hidden;
    list-style: none;

    li {
      position: relative;
      margin-bottom: 10px;
      padding-left: 20px;
      font-family: var(--font-mono);
      font-size: var(--fz-xs);

      &:before {
        content: '▹';
        position: absolute;
        left: 0;
        color: var(--green);
        font-size: var(--fz-sm);
        line-height: 12px;
      }
    }
  }
`;
const StyledPic = styled.div`
  position: relative;
  max-width: 500px;
  //max-width: 300px;

  @media (max-width: 768px) {
    margin: 50px auto 0;
    width: 70%;
  }

  .wrapper {
    ${({ theme }) => theme.mixins.boxShadow};
    display: block;
    position: relative;
    // max-width: 500px;
    width: 100%;
    //height: 200px;
    //height: auto;
    //width: 200px;
    //height: 200px;
    margin-top: 20px;
    margin-bottom: 20px !important;

    border-radius: var(--border-radius);
    //background-color: var(--green);

    &:hover,
    &:focus {
      background: transparent;
      outline: 0;

      &:after {
        //top: 15px;
        //left: 15px;
      }

      .img {
        filter: none;
        mix-blend-mode: normal;
        // max-width: 500px;
      }
    }

    .img {
      width: 100%;
      max-width: 500px;
      //width: 200px;
      //height: 200px;
  
      position: relative;
      border-radius: var(--border-radius);
      mix-blend-mode: multiply;
      filter: grayscale(100%) contrast(1);
      transition: var(--transition);
      
      border: 3px solid var(--green);
    }

    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      //height: 100%;
      // width: 200px;
      // height: 200px;
  
      border-radius: var(--border-radius);
      transition: var(--transition);
    }

    &:before {
      top: 0;
      left: 0;
      background-color: var(--navy);
      mix-blend-mode: screen;
    }

    &:after {
      //border-bottom: 3px solid var(--green);
      top: 20px;
      left: 20px;
      z-index: -1;
    }
  }
`;

const Services = () => {
  const revealContainer = useRef(null);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    sr.reveal(revealContainer.current, srConfig());
  }, []);

  const skills = ['Soluciones empresariales', 'Arquitectura', 'Dirección de proyectos', 'Outsourcing TI', 'Marketing Digital', 'Capacitación'];

  return (
    <StyledServicesSection id="services" ref={revealContainer}>
      <h2 className="numbered-heading">Servicios</h2>

      <div className="inner">
        <StyledText>
          <div>
            <chapter>
              <h3 className="numbered-subheading">Soluciones empresariales</h3>
              <h4 className="unnumbered-subheading">Desarrollo</h4>
              <p>
                Somos especialistas en diseño, desarrollo e implementación  de proyectos de software, posicionándonos como un partner estratégico de innovación y tecnología dentro de tu organización.
                Con nuestro equipo de primer nivel nos enfocamos en conocer tus procesos de negocios y necesidades reales de eficiencias de la era digital, buscando soluciones tecnológicas innovadoras que te permitan cumplir con tu planificación estratégica y sus objetivos, desde el frontend hasta la instalaciones en servidores cloud u on-promise y capacitaciones si se requieren.
              </p>

              <div className="wrapper">
                <StaticImage
                  className="img"
                  src="../../images/desarrollo.png"
                  width={500}
                  quality={95}
                  formats={['AUTO', 'WEBP', 'AVIF']}
                  alt="Headshot"
                />
              </div>

              <h3 className="unnumbered-subheading">Agile Product Launch/MVP</h3>
              <p>
                Mejores productos , más rápido. Colaboramos con las empresas, creando productos digitales e innovar mejor y más rápido.
              </p>

              <div className="wrapper">
                <StaticImage
                  className="img"
                  src="../../images/mvp.png"
                  width={500}
                  quality={95}
                  formats={['AUTO', 'WEBP', 'AVIF']}
                  alt="Headshot"
                />
              </div>

              <h3 className="unnumbered-subheading">Outsourcing & Células ágiles</h3>
              <p>
                Suma talentos adecuados a tu equipo o proyecto. Colaboramos con las empresas, creando equipos de trabajo de alto desempeño, aportando los equipos digitales que requiera en modalidad outsourcing, staffing y también profesionales en todos los segmentos.
              </p>

              <div className="wrapper">
                <StaticImage
                  className="img"
                  src="../../images/outsourcing-ti.png"
                  width={500}
                  quality={95}
                  formats={['AUTO', 'WEBP', 'AVIF']}
                  alt="Headshot"
                />
              </div>
            </chapter>

            <chapter>
              <h3 className="numbered-subheading">Marketing Digital</h3>
              <h4 className="unnumbered-subheading">Diseño</h4>
              <p>
                Obtendremos y revisaremos los artefactos requeridos para diseñar la mejor experiencia de usuario, desde mapas de interacción hasta llegar a la interfaz gráfica en el formato final acordado, de forma que lo tome el area de TI y genere la solución tecnológica prevista.            
              </p>

              <div className="wrapper">
                <StaticImage
                  className="img"
                  src="../../images/diseño.png"
                  width={500}
                  quality={95}
                  formats={['AUTO', 'WEBP', 'AVIF']}
                  alt="Headshot"
                />
              </div>

              <h4 className="unnumbered-subheading">Growth Hacking</h4>
              <p>
                Realizamos una auditoría de las fuentes de leads, nuevos negocios y tráfico para proponer un plan de crecimiento acelerado enfocado en experimentación digital con objetivos de corto y mediano plazo, que potencien resultados medibles y escalables              
              </p>

              <div className="wrapper">
                <StaticImage
                  className="img"
                  src="../../images/growth-hacking.png"
                  width={500}
                  quality={95}
                  formats={['AUTO', 'WEBP', 'AVIF']}
                  alt="Headshot"
                />
              </div>
            </chapter>

            <chapter>
              <h3 className="numbered-subheading">Capacitación</h3>
              <h4 className="unnumbered-subheading">E-learning</h4>
              <p>
                Entendemos tu proceso de digitalización de capacitación, aplicando el mejor diseño instruccional que logre cumplir con los objetivos de enseñanza establecidos en los plazos fijados, creando cursos cursos multimedia con tecnologia de primer nivel, superando las restricciones de distancia y convirtiendonos en tu partner estrátegico con un equipo de primer nivel, especialistas en  diseño, tecnologia y educación.
              </p>

              <div className="wrapper">
                <StaticImage
                  className="img"
                  src="../../images/growth-hacking.png"
                  width={500}
                  quality={95}
                  formats={['AUTO', 'WEBP', 'AVIF']}
                  alt="Headshot"
                />
              </div>
            </chapter>

          </div>

          {/* <ul className="skills-list">
            {skills && skills.map((skill, i) => <li key={i}>{skill}</li>)}
          </ul> */}
        </StyledText>

        {/* <StyledPic>
          <div className="wrapper">
            <StaticImage
              className="img"
              src="../../images/nosotros.png"
              width={450}
              quality={95}
              formats={['AUTO', 'WEBP', 'AVIF']}
              alt="Headshot"
            />
          </div>

          <div className="wrapper">
            <StaticImage
              className="img"
              src="../../images/scrum.png"
              width={450}
              quality={95}
              formats={['AUTO', 'WEBP', 'AVIF']}
              alt="Headshot"
            />
          </div>

          <div className="wrapper">
            <StaticImage
              className="img"
              src="../../images/equipo.png"
              width={450}
              quality={95}
              formats={['AUTO', 'WEBP', 'AVIF']}
              alt="Headshot"
            />
          </div>

          <div className="wrapper">
            <StaticImage
              className="img"
              src="../../images/desarrollo.png"
              width={500}
              quality={95}
              formats={['AUTO', 'WEBP', 'AVIF']}
              alt="Headshot"
            />
          </div>
          <div className="wrapper">
            <StaticImage
              className="img"
              src="../../images/mvp.png"
              width={500}
              quality={95}
              formats={['AUTO', 'WEBP', 'AVIF']}
              alt="Headshot"
            />
          </div>

          <div className="wrapper">
            <StaticImage
              className="img"
              src="../../images/diseño.png"
              width={500}
              quality={95}
              formats={['AUTO', 'WEBP', 'AVIF']}
              alt="Headshot"
            />
          </div>

        </StyledPic> */}
      </div>
    </StyledServicesSection>
  );
};

export default Services;
